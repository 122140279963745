import { formatDate, isPlatformBrowser } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID, ɵDEFAULT_LOCALE_ID } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastService } from './Toast.service';

import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { catchError, filter, map, switchMap } from 'rxjs/operators';
import { environment } from '../_environments/environment';
import { Address } from '../_model/address';
import { Compensation } from '../_model/compensation';
import { Customer, CustomerAddress, CustomerAllergen, CustomerLifestyle, Gender, Optintype } from '../_model/customer';
import { Sourcetype, TableParameters, TableResponse } from '../_model/shared';
import { Role } from '../_model/user';
import { Snackbarerrorformat } from '../_theme/snackbarformats';
import { MyValidators } from '../_validators/MyValidators';
import { AddressService } from './AddressService';
import { AuthService } from './AuthService';
import { GlobalService } from './GlobalService';
import { SessionstorageService } from './SessionstorageService';
import { WINDOW } from './WindowService';

@Injectable({
  providedIn: 'root',
})
export class CustomerService {

  customerRefreshTime: number = 60 * 15 * 1000 // 15 min
  customerRefreshTimer: any;

  hoursSinceCustomercreated = 0;

  customer: Customer;

  PHONE_REGEX = /(^\+[0-9]{2}|^\+[0-9]{2}\(0\)|^\(\+[0-9]{2}\)\(0\)|^00[0-9]{2}|^0)([0-9]{8}$|[0-9]{9}$|[0-9\-\s]{10}$)/;

  customerForm: FormGroup = this.initCustomer();
  //needs seperate customerform for orderflowModule, bc in de other customerform the address and user are added into the form
  customerFlowform: FormGroup = this.initCustomer();

  userForm = new FormGroup({
    id: new FormControl(null),
    username: this.customerFlowform.get('email'),
    password: new FormControl(null, { validators: [Validators.required, Validators.minLength(8)] }),
    confirmPassword: new FormControl(null, { validators: [Validators.required, Validators.minLength(8), this._myValidators.ConfirmPassword] }),
    userroles: this.fb.array([{ id: null, role: Role.CUSTOMER }]),
    brandid: new FormControl(environment.brandid, [Validators.required, Validators.min(1)]),
  })
  get customeraddresses(): FormArray {
    return this.customerForm.get('customerAddresses') as FormArray
  }
  public customerSubject: BehaviorSubject<Customer> = new BehaviorSubject<Customer>(null);

  constructor(
    private _http: HttpClient,
    public fb: FormBuilder,
    private _authenticationService: AuthService,
    private _addressService: AddressService,
    private _globalService: GlobalService,
    private _translate: TranslateService,
  private _toastService: ToastService,

    private _myValidators: MyValidators,
    private sessionStorage: SessionstorageService,
    @Inject(WINDOW) private window: Window,
    @Inject(PLATFORM_ID) private platformId: any,
  ) {

    this._authenticationService.isAuthenticatedSubject.subscribe(value => {
      // gets and sets or removes customer based on authenticationstatus
      if (value) {
        this.sessionStorage.removeItem('customer');
        this.sessionStorage.removeItem('flowcustomer');
        const user = _authenticationService.getCurrentUser();
        if (!user || !user.customerid) { return; }
        this.getCustomer(user.customerid, false, false).subscribe(result => {

          this._addressService.getAddressList(Array.from(new Set(result.customerAddresses.map(a => a.addressid)))).subscribe(addresses => {

            //needs to patch shippingaddress to addressform after loadcustomer,
            //  because addressform is added to customerform in subscriptionService and reset in loadcustomer
            let shippingaddress = null;
            result.customerAddresses.forEach(custaddress => {
              custaddress.address = addresses.find(a => a.id == custaddress.addressid)

              // sets pricing countrycode based on customer shipping address
              if (result.defaultcustomershippingaddress) {
                if (custaddress.id == result.defaultcustomershippingaddress) {
                  this._globalService.setPricecountry(Sourcetype.CUSTOMER, custaddress.address.countrycode);
                  shippingaddress = custaddress.address;
                }
              } else {
                if (custaddress.shippingaddress) {
                  this._globalService.setPricecountry(Sourcetype.CUSTOMER, custaddress.address.countrycode);
                  shippingaddress = custaddress.address;
                }
              }
            });

            this.customer = result;
            this.loadCustomer(result);
            this.loadCustomer(result, true);
            this.customerSubject.next(result);

            this._addressService.addressForm.patchValue(shippingaddress)
            if (!this._addressService.shippingAddressForm.valid)
              this._addressService.shippingAddressForm.patchValue(shippingaddress)
          })
        });

      } else {
        //this.sessionStorage.removeItem('customer');
        this.customer = null;
        this.customerSubject.next(null);
        this.customerForm.reset({ brandid: environment.brandid });
        this.customerFlowform.reset({ brandid: environment.brandid, email:'', donotcontact:true });
        //this.customerFlowform.reset();
        //this.customerFlowform = this.initCustomer();
        if (isPlatformBrowser(this.platformId))
          this.window.clearTimeout(this.customerRefreshTimer);
      }
    },
      () => { },
      () => {
        this.sessionStorage.removeItem('customer');
        this.customer = null;
        this.customerSubject.next(null);
        this.customerForm.reset({ brandid: environment.brandid });
        this.customerFlowform.reset({ brandid: environment.brandid, email: '', donotcontact:true });
        if (isPlatformBrowser(this.platformId))
          this.window.clearTimeout(this.customerRefreshTimer);
      });

    const sessionCustomer = this.sessionStorage.getObject('customer')
    if (sessionCustomer)
      this.loadCustomer(sessionCustomer)
    //this.customerForm.patchValue(sessionCustomer);

    this.customerForm.valueChanges.pipe(
      filter(() => !this._authenticationService.isAuthenticatedSubject.value)
    ).subscribe(res => {
      this.sessionStorage.setObject('customer', res)
    })

    this.customerFlowform.valueChanges.subscribe(res => {
      if (!this._authenticationService.isAuthenticatedSubject.value)
        this.sessionStorage.setObject('flowcustomer', res)
    })

    const sessionflowCustomer = this.sessionStorage.getObject('flowcustomer')
    if (sessionflowCustomer)
      this.loadCustomer(sessionflowCustomer, true)

    this.customerSubject.subscribe(val=>{
      if(val && val.created){
        this.hoursSinceCustomercreated = (Date.now() - val.created) / 3600000;
      }else{
        this.hoursSinceCustomercreated = 0
      }
    })
  }

  sendDeldicoData() {
    try {
      const userdata = {
        name: this.customerForm.get('firstname').value + ' ' + this.customerForm.get('prefix').value + ' ' + this.customerForm.get('lastname').value,
        email: this.customerForm.get('email').value,
        phone: this.customerForm.get('phonenumber').value
      }
      userdata.name = userdata.name.replace(/ null/g, "");

      fetch('https://ekomenu-tracking.deldico.be/event', {
        keepalive: true,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-Api-Key': 'vbcEaC&rp*BB3ci!$*z8Wx@X5pRQ#9Fp',
        },
        body: JSON.stringify(
          {
            fingerprint: this._globalService.trackingClient.fingerprint,
            trackingId: this._globalService.trackingClient.trackingId,
            path: '/',
            pageTitle: '',
            utmCampain: '',
            utmSource: '',
            utmTerm: "",
            utmContent: "",
            event: "new_lead",
            timestamp: new Date(),
            userData: userdata,
            metaData: { context: "signup" }
          }
        ),
      });
    } catch (e) {
      console.warn(e);
    }
  }
  get getCustomerName(): string {
    const customer = this.customerForm.getRawValue() as Customer
    return (customer.firstname ? customer.firstname : "") + (customer.prefix ? ' ' + customer.prefix : '') + (customer.lastname ? ' ' + customer.lastname : '')
  }
  get getCustomerShippingAddressId(): number {
    if (this.customerForm.get('defaultcustomershippingaddress').value)
      return this.customerForm.get('defaultcustomershippingaddress').value
    return this.customeraddresses.getRawValue()[0]?.id
  }
  get getCustomerBillingAddressId(): number {
    if (this.customerForm.get('defaultcustomerbillingaddress').value)
      return this.customerForm.get('defaultcustomerbillingaddress').value
    return this.customeraddresses.getRawValue()[0]?.id
  }
  createTicket(data: any): Observable<any> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const options = { headers: headers };
    const body = JSON.stringify(data);

    return this._http.post(environment.apiserver + 'customer/v2/customer/ticket', body, options).pipe(
      map((response: any) => {
        return response;
      })
      , catchError(error => this.handleError(error, 'contactform.error'))
    );
  }

  private resetTimer() {
    if (isPlatformBrowser(this.platformId))
      this.window.clearTimeout(this.customerRefreshTimer)
    this.customerRefreshTimer = setTimeout(() => {
      console.log('executed customer timeout', new Date())
      const user = this._authenticationService.getCurrentUser();
      this.customer = null;
      this.getCustomer(user.customerid).subscribe();
    }, this.customerRefreshTime)
  }

  initCustomer(): FormGroup {
    return new FormGroup({
      id: new FormControl(null),
      brandid: new FormControl(environment.brandid, [Validators.required, Validators.min(1)]),
      firstname: new FormControl(null, Validators.required),
      prefix: new FormControl(null),
      lastname: new FormControl(null),
      email: new FormControl(''),
      phonenumber: new FormControl(null, {
        validators: [
          Validators.required,
          Validators.minLength(9), // phonenumber are at least 10 chars
          Validators.pattern(this.PHONE_REGEX), // some more retails
        ]
      }),
      gender: new FormControl(Gender.UNKNOWN),
      birthdate: new FormControl(null),
      preferredlanguage: new FormControl(null),
      countrycode: new FormControl(null),
      mandateid: new FormControl(''),
      mandateenabled: new FormControl(false),
      mandatedisablereason: new FormControl(''),
      donotcontact: new FormControl(true),
      defaultcustomershippingaddress: new FormControl(null),
      defaultcustomerbillingaddress: new FormControl(null),
      created: new FormControl(null),
      updated: new FormControl(null),
      affiliateid: new FormControl(null),
      comments: new FormControl(''),
      customerAllergens: this.fb.array([]),
      customerLifestyles: this.fb.array([], Validators.required),
      customerAddresses: this.fb.array([]),
    })
  }

  getCustomer(id: number, fromServer = false, runLoadCustomer = true): Observable<Customer> {
    if (!fromServer)
      if (this.customer && this.customer.id === id) {
        return of(this.customer);
      } else {
        this.customer = null;
      }

    return this._http.get(environment.apiserver + 'customer/v1/customer/' + id).pipe(
      map((response: Customer) => {
        if (this._globalService.activeLanguage && this._globalService.activeLanguage.languagetype == Sourcetype.CUSTOMER) {
          console.info("keep lang found in local storage, dont override it now by the stored cust preference");
        } else {
          this._globalService.setLanguage(Sourcetype.CUSTOMER, response.preferredlanguage);
        }
        this.customer = response;
        this.customerSubject.next(response);

        if (runLoadCustomer)
          this.loadCustomer(response);
        this.resetTimer();

        return response as Customer;
      })
      , catchError(error => this.handleError(error, 'customer.get_customer_error'))
    );
  }
  private updateCustomerWithLastdata(customerForm: FormGroup, changedValues: any = null): Observable<Customer> {
    if (customerForm.get('id').value > 0) {
      if (!changedValues)
        changedValues = this._globalService.getDirtyValues(customerForm);
      return this.getCustomer(customerForm.get('id').value, true, false).pipe(
        switchMap((res: Customer) => {
          //makes sure that there are no duplicate indexes for the customerlifestyles
          if (changedValues.customerLifestyles) {
            changedValues.customerLifestyles.forEach((l: CustomerLifestyle) => {
              if (!l) return;
              const lifestyle = res.customerLifestyles.find(s => s.lifestyleid === l.lifestyleid);
              if (!lifestyle) return;
              l.id = lifestyle.id
            })
          }

          //makes sure that there are no duplicate indexes for the customerallergens
          if (changedValues.customerAllergens) {
            changedValues.customerAllergens.forEach((l: CustomerAllergen) => {
              if (!l) return;
              const allergen = res.customerAllergens.find(s => s.name === l.name);
              if (!allergen) return;
              l.id = allergen.id
            })
          }

          res = this._globalService.mergeDirtyValues(changedValues, res);
          return of(res);
        })
      );
    } else {
      return of(customerForm.getRawValue());
    }
  }
  saveCustomer(customerForm: FormGroup, changedValues: any = null): Observable<Customer> {

    return this.updateCustomerWithLastdata(customerForm, changedValues).pipe(
      switchMap((customer: Customer) => {

        const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        const options = { headers: headers };

        customer.brandid = environment.brandid;

        customer.updated = Date.now();
        if (customer.birthdate) {
          customer.birthdate = formatDate(customer.birthdate, 'yyyy-MM-dd', ɵDEFAULT_LOCALE_ID);
        }
        //gets list of unique allergens without null values, to overcome saving errors
        customer.customerAllergens = customer.customerAllergens.filter(f => f);
        customer.customerAllergens = [...new Map(customer.customerAllergens.map(item => [item.name, item])).values()]

        //gets list of unique lifestyles without null values, to overcome saving errors
        customer.customerLifestyles = customer.customerLifestyles.filter(f => f);
        customer.customerLifestyles = [...new Map(customer.customerLifestyles.map(item => [item.lifestyleid, item])).values()]

        customer.customerAddresses = customer.customerAddresses.filter(f => f);
        customer.customerAddresses = [...new Map(customer.customerAddresses.map(item => [item.addressid, item])).values()]

        if (customer.id > 0) {
          const body = JSON.stringify(customer);
          return this._http.put(environment.apiserver + 'customer/v1/customer/' + customer.id, body, options).pipe(
            map((response: Customer) => {
              this.customer = response;
              this.customerSubject.next(response);
              this.updateCustomer(response);
              this.resetTimer();

              return response;
            })
            , catchError(error => this.handleError(error, 'customer.save_customer_error'))
          );
        } else {
          customer.created = Date.now();

          const body = JSON.stringify(customer);
          return this._http.post(environment.apiserver + 'customer/v2/customer', body, options).pipe(
            map((response: Customer) => {
              this.customer = response;
              this.customerSubject.next(response);
              this.updateCustomer(response);
              this.resetTimer();

              return response;
            })
            , catchError(error => this.handleError(error, 'customer.save_customer_error'))
          );
        }
      }));
  }
  changemandate(): Observable<string> {
    return this._http.get(environment.apiserver + 'customer/v1/customer/changemandate', { responseType: 'text' }).pipe(
      map((response) => {
        return response as string;
      })
      , catchError(error => this.handleError(error, 'customer.change_mandate_error'))
    );
  }

  /**
   * @deprecated the serverside method is not adjusted to be able to work with klaviyo. The opt-in is now handled by the donotcontact in the post of the customer
   * 
   * @param email 
   * @param subscribed 
   * @param origin 
   * @returns 
   */
  newsletterSubscription(email: string, subscribed = true, origin = 'website'): Observable<boolean> {

    const headers = new HttpHeaders({ 'Content-Type': 'application/json', });
    const options = { headers: headers };

    const obj = {
      email: email,
      origin: origin,
      brandid: environment.brandid,
      subscribed: subscribed == null ? false : subscribed
    };

    const body = JSON.stringify(obj);
    return this._http.post(environment.apiserver + 'customer/v1/customer/subscribe', body, options).pipe(
      map((response: any) => <any>response)
      , catchError(error => this.handleError(error))
    );


  }
  tellAFriend(recipientEmail: string, message: string) {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const options = { headers: headers };

    const obj = {
      recipient: recipientEmail,
      message: message
    };

    const body = JSON.stringify(obj);
    return this._http.post(environment.apiserver + 'customer/v1/customer/sendtaf', body, options).pipe(
      map((response: any) => <any>response)
      , catchError(error => this.handleError(error))
    );
  }
  getCompensations(tableParameters: TableParameters): Observable<TableResponse<Compensation>> {
    return this._http.get(environment.apiserver + 'compensation/v1/compensation/customer/' + this._authenticationService.getCurrentUser().customerid + tableParameters.getUrlParameters().toString()).pipe(
      map((response) => {
        return response as TableResponse<Compensation>;
      })
      , catchError(error => this.handleError(error, 'customer.get_compensations_error'))
    );
  }

  getGenders(): string[] {
    return Object.keys(Gender);
  }
  getOptins(): string[] {
    return Object.keys(Optintype);
  }

  private loadCustomer(customer: Customer, flowform = false) {
    //makes sure that the address in the onboarding is not cleared

    let form = this.customerForm;
    if (flowform)
      form = this.customerFlowform

    form.reset({ brandid: environment.brandid });
    form.patchValue(customer, { emitEvent: false });
    form.get('mandateenabled').disable();

    this.getCustomerAddressFormControls.clear();
    customer.customerAddresses.sort((a, b) => a.id - b.id)
    if (customer.customerAddresses) {
      customer.customerAddresses.forEach(add => {
        this.addCustomerAddress(add);
      });
    }
    this.getAllergenFormControls.clear();
    if (customer.customerAllergens) {
      customer.customerAllergens.forEach(all => {
        this.addAllergen(all);
      });
    }

    this.getCustomerLifestyles.clear();
    if (customer.customerLifestyles) {
      customer.customerLifestyles.forEach(lif => {
        this.addLifeStyle(lif);
      });
    }
  }
  private updateCustomer(customer: Customer) {
    this.customerForm.patchValue(customer, { emitEvent: false });
    this.customerFlowform.patchValue(customer, { emitEvent: false });

    this.getCustomerAddressFormControls.clear();
    this.customer.customerAddresses.sort((a, b) => a.id - b.id)
    customer.customerAddresses.forEach(add => {
      this.addCustomerAddress(add);
    });

    //this.getAllergenFormControls.clear();
    //if (customer.customerAllergens) {
    //  customer.customerAllergens.forEach(all => {
    //    this.addAllergen(all);
    //  });
    //}

    //this.getCustomerLifestyles.clear();
    //if (customer.customerLifestyles) {
    //  customer.customerLifestyles.forEach(lif => {
    //    this.addLifeStyle(lif);
    //  });
    //}

    //console.log(this.customerForm.value, customer)

  }

  //Lifestyles
  get getCustomerLifestyles(): FormArray {
    return this.customerForm.get('customerLifestyles') as FormArray;
  }
  get getCustomerFlowLifestyles(): FormArray {
    return this.customerFlowform.get('customerLifestyles') as FormArray;
  }
  private initiateLifeStyle(): FormGroup {
    const fgroup = this.fb.group({
      id: [null],
      lifestyleid: ['', [Validators.required]]
    });
    return fgroup;
  }
  addLifeStyle(styleData: CustomerLifestyle = null) {
    if (!styleData || !styleData.lifestyleid) return;
    const control = this.getCustomerLifestyles;
    const flowControl = this.getCustomerFlowLifestyles;

    const fg = this.initiateLifeStyle();
    fg.patchValue(styleData);
   
    //doesnt allow more than 1 lifestyle if there is a slingle one left
    if (control.length === 1 && styleData.id == null) {
      control.clear();
      flowControl.clear();
    }
    if (control.value.filter(l => l.lifestyleid === styleData.lifestyleid).length === 0) {
      control.push(fg);
      if (styleData.id == null) {
        fg.markAsDirty();
        control.markAsDirty();
      }
    }

    if (flowControl.value.filter(l => l.lifestyleid === styleData.lifestyleid).length === 0) {
      flowControl.push(fg);
      if (styleData.id == null) {
        fg.markAsDirty();
        flowControl.markAsDirty();
      }
    }

  }
  deleteLifeStyle(lifeStyleId: number) {
    if (this.countLifeStyles() < 2) { return; }
    const control = this.getCustomerLifestyles;
    const flowControl = this.getCustomerFlowLifestyles;

    const index = control.controls.map((e) => {
      return e.get('lifestyleid').value;
    }).indexOf(lifeStyleId);
    control.removeAt(index);
    control.markAsDirty();

    const flowindex = control.controls.map((e) => {
      return e.get('lifestyleid').value;
    }).indexOf(lifeStyleId);
    flowControl.removeAt(flowindex);
    flowControl.markAsDirty();

  }
  private countLifeStyles() {
    const control = this.getCustomerLifestyles;
    return control.controls.filter(lifestyle => !!lifestyle.get('lifestyleid').value).length;
  }

  // Allergens
  get getAllergenFormControls(): FormArray {
    return this.customerForm.get('customerAllergens') as FormArray;
  }
  get getAllergenFlowFormControls(): FormArray {
    return this.customerFlowform.get('customerAllergens') as FormArray;
  }
  private initiateAllergen(): FormGroup {
    const fgroup = new FormGroup({
      id: new FormControl(null),
      name: new FormControl(null, [Validators.required])
    });
    return fgroup;
  }
  addAllergen(allergenData: CustomerAllergen) {
    const control = this.getAllergenFormControls;
    const flowControl = this.getAllergenFlowFormControls;
    const fg = this.initiateAllergen();

    if (!allergenData.name) return;

    fg.patchValue(allergenData);

    if (control.value.filter((a: CustomerAllergen) => a.name === allergenData.name).length === 0) {
      control.push(fg);
      if (allergenData.id == null) {
        fg.markAsDirty();
        control.markAsDirty();
      }
    }

    if (flowControl.value.filter((a: CustomerAllergen) => a.name === allergenData.name).length === 0) {
      flowControl.push(fg);
      if (allergenData.id == null) {
        fg.markAsDirty();
        flowControl.markAsDirty();
      }
    }

  }
  deleteAllergen(allergen: string) {
    const control = this.getAllergenFormControls;
    const flowControl = this.getAllergenFlowFormControls;
    const index = control.controls.map(e=> {
      return e.get('name').value;
    }).indexOf(allergen);
    control.removeAt(index);
    control.markAsDirty();

    const flowIndex = flowControl.controls.map(e=> {
      return e.get('name').value;
    }).indexOf(allergen);
    flowControl.removeAt(flowIndex);
    flowControl.markAsDirty();

  }

  //addresses
  get getCustomerAddressFormControls() {
    return this.customerForm.get('customerAddresses') as FormArray;
  }
  get getCustomerAddressFlowFormControls() {
    return this.customerFlowform.get('customerAddresses') as FormArray;
  }
  initiateCustomerAddress(): FormGroup {
    const fgroup = this.fb.group({
      id: new FormControl(null),
      addressid: new FormControl(null, Validators.required),
      shippingaddress: new FormControl(false),
      billingaddress: new FormControl(false),
      name: new FormControl(''),
      address: new FormControl(null)
    });

    return fgroup;
  }
  addCustomerAddress(address: CustomerAddress = null) {
    if (!address.addressid) { return; }

    const addr = this.initiateCustomerAddress();
    if (address) {
      addr.patchValue(address)
      this.setAddress(addr)
    }

    if (this.getCustomerAddressFormControls.getRawValue().filter(a => a.addressid === address.addressid).length === 0) {
      const control = this.getCustomerAddressFormControls;
      control.push(addr);
      if (address.id == null) {
        addr.markAsDirty();
        control.markAsDirty();
      }

    }
    if (this.getCustomerAddressFlowFormControls.getRawValue().filter(a => a.addressid === address.addressid).length === 0) {
      const flowControl = this.getCustomerAddressFlowFormControls;
      flowControl.push(addr);
      if (address.id == null) {
        addr.markAsDirty();
        flowControl.markAsDirty();
      }

    }

  }
  private async setAddress(fg: FormGroup) {
    if (!fg.get('address').value)
      fg.get('address').setValue(await this._addressService.getAddress(fg.get('addressid').value).toPromise())
  }
  removeCustomeraddress(index: number) {
    this.getCustomerAddressFormControls.removeAt(index);
    this.getCustomerAddressFlowFormControls.removeAt(index);
    this.getCustomerAddressFlowFormControls.markAsDirty()
    this.customerForm.markAsDirty();
  }
  getAddressByCustomeraddressId(customeraddressid): Address {
    const custaddr = this.getCustomerAddressFormControls.controls.filter(c => c.value.id == customeraddressid)[0];
    if (!custaddr || !custaddr.value.addressid) return null;

    custaddr.get('address').setValue(this._addressService.addresses.filter(a => a.id == custaddr.value.addressid)[0])
    return custaddr.get('address').value;
  }
  getCustomerAddressByAddressId(Addressid: number): CustomerAddress {
    if (!Addressid) return null;
    const custaddr = this.getCustomerAddressFormControls.controls.find(c => c.value.addressid == Addressid);
    return custaddr.value;
  }
  getCustomerDefaultShippingaddress(): Address {
    if (!this.customer || !this.customer.defaultcustomershippingaddress) return null;
    let custaddress = this.customeraddresses.value.filter(a => a.addressid = this.customer.defaultcustomershippingaddress)[0];
    if (custaddress) {
      if (custaddress.address) return custaddress.address;
      return this.getAddressByCustomeraddressId(custaddress.addressid);
    }
    return null;
  }
  getCustomerDefaultBillingaddress(): Address {
    if (!this.customer || !this.customer.defaultcustomerbillingaddress) return null;
    let custaddress = this.customeraddresses.value.filter(a => a.addressid = this.customer.defaultcustomerbillingaddress)[0];
    if (custaddress) {
      if (custaddress.address) return custaddress.address;
      return this.getAddressByCustomeraddressId(custaddress.addressid);
    }
    return null;
  }

  private handleError(error: Response, msgText: string = null) {
    if (msgText)
    this._toastService.open
(this._translate.instant(msgText), null, Snackbarerrorformat)

    if (error.status === 400) {
      return throwError(() => 'Bad request');
    }
    return throwError(() => error || 'Server error');

  }

}

