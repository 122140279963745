import { Countrycode, Currency } from './shared';
import { Paymentmethod, Shippingmethod } from './order';
import { Storetype } from './postalcodedelivery';

export class Price {
  constructor(
    public id: number,
    public brandid: number,
    public countrycode: Countrycode,
    public vatlow: number,
    public vathigh: number,
    public onepersonrecipediscountpercentage: number,
    public onepersonrecipediscountamount: number,
    public twopersonrecipediscountpercentage: number,
    public twopersonrecipediscountamount: number,
    public threepersonrecipediscountpercentage: number,
    public threepersonrecipediscountamount: number,
    public fourpersonrecipediscountpercentage: number,
    public fourpersonrecipediscountamount: number,
    public onepersonrecipeprice: number,
    public twopersonrecipeprice: number,
    public threepersonrecipeprice: number,
    public fourpersonrecipeprice: number,
    public fourbagsdiscountpercentage: number,
  ) {
  }
}

export class Minimumordervalue {
  constructor(
    public id: number,
    public brandid: number,
    public countrycode: Countrycode,
    public minimumtotalvalue: number,
    public shippingmethod: Shippingmethod,
    public shippingcost: number,
    public shippingpercentage: number,
    public minimumordervaluetype: Minimumordervaluetype
  ) {
  }
}

export enum Minimumordervaluetype {
  SHIPPING = "SHIPPING",
  BASKETVALUE = "BASKETVALUE"
}

export class CalculatedPrice {
  constructor(
    public priceincvat: number,
    public priceexvat: number,
    public vat: number,
    public vatpercentage: number,
    public discountincvat: number,
    public discountexvat: number,
    public discountvat: number,
    public calculateddiscountpercentage: number,
    public shippingcost: number,
    public shippingcostvat: number,
    //public coupondiscount: number,
    //public coupondiscountpercentage: number,
    public currency: Currency,
    public appliedcouponcode: string,
    public appliedcompensations: AppliedCompensation[],
    public totalcompensated: number,
    public basketmovreached: boolean,
    public basketmovvalue:number
  ) {
  }
}

export class AppliedCompensation {
  constructor(
    public compensationid: number,
    public reason: string,
    public compensated: number
  ) {}
}

export class CustomerCreateOrderRequest {
  public customerid: number;
  public email: string;
  public brandid: number;
  public currency: Currency;
  public originentityid: number;
  public originentitytype: string;
  public shippingmethod: Shippingmethod;
  public deliverydate: any;
  public pickupdeliverytimeframe: string;
  public pickupstore: string;
  public firstsubscriptionorder: boolean;
  public storeid: number;
  public storetype: Storetype;
  public shippingtoname: string;
  public billingtoname: string;
  public shippingaddress: number;
  public billingaddress: number;

  public shippingaddresscountrycode: Countrycode;
  public calculatedPrice: CalculatedPrice;
  public couponcode: string;
  public couponcodedidnotreachmov: boolean;
  public recipecard: boolean;
  public paymentmethod: Paymentmethod;
  public bic: string;
  public orderlines: CustomerCreateOrderLine[]
  constructor(
    
  ) {

  }
}

export class CustomerCreateOrderLine {

  constructor(
    public sku: string,
    public recipeid: number,
    public boxid: number,
    public persons: number,
    public description: string,
    public quantity: number,
    public issubscriptionitem: boolean,
    public webshopitem: boolean,
    public onetimebox:boolean,
    public couponcodeitem: boolean,
    public doublepersons:boolean,
    public calculatedPrice: CalculatedPrice
  ) {

  }
}


// export enum IdealIssuers {
//   ABNANL2A = 'ABNANL2A',
//   ASNBNL21 = 'ASNBNL21',
//   BUNQNL2A = 'BUNQNL2A',
//   INGBNL2A = 'INGBNL2A',
//   KNABNL2H = 'KNABNL2H',
//   RABONL2U = 'RABONL2U',
//   RBRBNL21 = 'RBRBNL21',
//   REVOLT21 = 'REVOLT21',
//   SNSBNL2A = 'SNSBNL2A',
//   HANDSESS = 'HANDSESS',
//   TRIONL2U = 'TRIONL2U',
//   FVLBNL22 = 'FVLBNL22',

// }

export enum Creditcardnetwork {
  VISA = "VISA",
  MASTERCARD = "MASTERCARD"
}



